import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { graphql } from 'gatsby';
import withRoot from '../withRoot';
import MetaPanel from '../components/meta-panel';
import RealmCard from '../components/realm-card';
import { RichText } from 'prismic-reactjs';
import LinkResolver from '../utils/linkResolver';
import HtmlSerializer from '../utils/htmlSerializer';

const styles = (theme) => ({
    description: {
        color: theme.palette.secondary.main,
        padding: `3px 20px`,
        [theme.breakpoints.up(`sm`)]: {
            padding: `5px 30px`
        },
        backgroundColor: `#F3F3F3`,
        borderTop: `1px solid #000`,
        borderBottom: `1px solid #000`,
        fontSize: `1.15em`
    },
    realms: {
        display: `flex`,
        flexWrap: `wrap`,
        margin: `30px 20px`,
        [theme.breakpoints.up(`sm`)]: {
            margin: `10px`
        },
        '& > div': {
            [theme.breakpoints.up(`sm`)]: {
                flexGrow: 1,
                flex: `1 1 45%`,
                margin: `10px`
            },
            [theme.breakpoints.up(`md`)]: {
                flexGrow: 1,
                flex: `1 1 30%`,
                margin: `15px`
            }
        }
    },
    flexPlaceholder: {}
});

const backgroundColours = [
    `#059A92`,
    `#B38755`,
    `#E2583E`,
    `#FDC821`,
    `#9CD2AE`
];
let count = 0;

class Realms extends React.Component {
    getDescriptionText = (realm) => {
        if (realm.description) {
            return RichText.asText(realm.description);
        }
        return RichText.asText(realm.name);
    };

    getDescription = (realm) => {
        if (realm.description) {
            return RichText.render(
                realm.description,
                LinkResolver,
                HtmlSerializer
            );
        }
        return ``;
    };

    getRealms = (realms) => {
        if (realms) {
            return realms.map((node) => (
                <RealmCard
                    name={RichText.asText(node.realm.name)}
                    backgroundColour={this.cycleBackgroundColour()}
                    icon={node.realm.icon}
                    url={`/${node.realm._meta.uid}`}
                    link={node.realm.coming_soon !== `Yes`}
                    key={node.realm._meta.uid}
                />
            ));
        }
        return ``;
    };

    cycleBackgroundColour = () => {
        if (count === backgroundColours.length) {
            count = 0;
        }
        return backgroundColours[count++];
    };

    render() {
        const { classes, data } = this.props;
        const realmlist = data.prismic.realmlist;
        const realms = this.getRealms(realmlist.realms);

        let name = `Realms`,
            description = this.getDescription(realmlist);

        return (
            <Layout>
                <SEO
                    title={name}
                    description={this.getDescriptionText(realmlist)}
                />
                <MetaPanel title={name} icon={`fort-awesome`} />
                <div className={classes.description}>{description}</div>
                <div className={classes.realms}>
                    {realms}
                    <div className={classes.flexPlaceholder} />
                    <div className={classes.flexPlaceholder} />
                    <div className={classes.flexPlaceholder} />
                    <div className={classes.flexPlaceholder} />
                </div>
            </Layout>
        );
    }
}

Realms.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
};

export default withRoot(withStyles(styles, { withTheme: true })(Realms));

export const pageQuery = graphql`
    query RealmsQuery {
        prismic {
            realmlist(uid: "realms", lang: "en-gb") {
                description
                realms {
                    realm {
                        __typename
                        ... on PRISMIC_Realm {
                            name
                            icon
                            coming_soon
                            _meta {
                                uid
                            }
                        }
                    }
                }
            }
        }
    }
`;
